import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import { Link , graphql } from 'gatsby';
import Layout from '../components/Layout';
import { GatsbyImage , getImage } from "gatsby-plugin-image";
import '../style/career-subpage.sass';
import useWindowSize from '../hooks/useWindowSize';

const loaderStyle = {
  width: '25px',
  height: '25px',
  border: '3px solid #FFF',
  borderBottomColor: 'transparent',
  borderRadius: '50%',
  display: 'inline-block',
  boxSizing: 'border-box',
  
};

const PageContent = ({
    backButton,
    fontColor,
    title,
    jobTitle,
    buttonLabel,
    buttonColor,
    logo,
    subDetails,
    questions,
    jobData,
    joinUs
  }) => {

    const TitleFragment = ({ fragment, color }) => (
        <span className='
        ' style={{ color }}>{fragment + " "}</span>
      );
    
  const arrow = getImage(logo)
  const { width } = useWindowSize();

   const [formData, setFormData] = useState({
      name: '',
      email: '',
      phone: '',
     attachment: '',
     isChecked: false,
     message:''
    });
  
    const [formErrors, setFormErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [currentJob,setCurrentJob]=useState(null)
  const [currentJobTitle,setCurrentJobTitle]=useState(null)
  const [currentJobSubDetail,setCurrentJobSubDetail]=useState(null)
  const [currentJobDesc, setCurrentJobDesc] = useState(null)
  const [currentJoinUsData, setCurrentJoinUsData] = useState(null)
  const [emailLoading,setEmailLoading]=useState(false)
  console.log(`NODE_ENV is set to: ${process.env.NODE_ENV}`);
  
  const validateForm = () => {
      console.log('inside validate')
    const errors = {};
    const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/; 
    const phoneRegex = /^(\+?\d{1,3}[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/;
    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) errors.email = "Email is required";
      if (!emailRegex.test(formData.email)) errors.email = "Enter a valid Email";
      if (!phoneRegex.test(formData.phone)) errors.phone = "Enter a valid Phone number";
      if (!formData.attachment) errors.attachment = "CV/Resume is required"
      if(!formData.isChecked) errors.isChecked = "Make sure to agree with terms and conditions"
    return errors;
    };
  
  
   const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      // Sending email using EmailJS
      e.preventDefault()
      try {

        setEmailLoading(true)
        const formDataRef = new FormData();
  formDataRef.append("name", formData.name);
  formDataRef.append("email", formData.email);
  formDataRef.append("phone", formData.phone);
  formDataRef.append("attachment", formData.attachment);
        formDataRef.append("title", currentJobTitle?.heading);
        
        if (formData?.message) {
          formDataRef.append("message", formData.message);
        }
        const response = await fetch('/api/careerEmail', {
          method: 'POST',
          body: formDataRef,
        });
    
        const result = await response.json();
        if (response.status === 200) {
          setEmailLoading(false)
          setIsSubmitted(true);
          // console.log('Email sent successfully:', result);
        } else {
          setEmailLoading(false)
          // console.log('Email sending failed:', result);
        }
      } catch (error) {
        setEmailLoading(false)
        // console.error('Error sending email:', error);
      }
    }
   };
  
  useEffect(() => {
    console.log('window.location.search:::::',window.location.search)
    const jobId = window.location.search.split('=')[1];
    console.log('jobId:::::', jobId)
    console.log('questions::::::::', questions)
    console.log('joinUs:::::',joinUs)
    if (jobId) {
      const jobRef = jobData.find((child) => child.id == jobId);
      const jobTitleRef = jobTitle.find((child)=>child.id==jobId)
      const jobDetail = subDetails.find((child) => child.id == jobId)
      const jobDesc = questions.answer1.find((child) => child.id == jobId)
      const joinUsRef=joinUs.data.find((child)=>child.id==jobId)
      if (jobRef) {
        setCurrentJob(jobRef)
      }

      if (jobTitleRef) {
        setCurrentJobTitle(jobTitleRef)
      }

      if (jobDetail) {
        setCurrentJobSubDetail(jobDetail)
      }

      if (jobDesc) {
        setCurrentJobDesc(jobDesc)
        
      }

      if (joinUsRef) {
        setCurrentJoinUsData(joinUsRef)
      }
    }
    
  },[])
    
      return(
        <div className='careers-main-container'>
            <div className='careers-container'>
            <Link to='/careers'>
                <div className='careers-container-backbutton'>
                    {logo ? (
                    <GatsbyImage image={arrow} alt='Loading...' className='arrow'/>
                    ) : (
                    <img src={arrow} alt='Loading...' className='arrow'/>
                    )}
                        <p style={{color:fontColor , fontWeight: '500' , lineHeight: '1.2' , fontSize: `clamp(0.9rem , 1vw , 1.2rem)`}}>{backButton}</p>
                </div>
              </Link>
    
            <div style={{flexDirection:width>768?'row':'column',display:'flex',justifyContent:'center',gap:'3%'}} className='careers-wrapper-container'>
              

              {/* left section */}
              <div style={{width:width>768?'60%':'100%'}} className='careers-left'>
                 <div className='careers-container-sub-1'>
                <p style={{color:buttonColor}}>{currentJobTitle?.heading}</p>
                {/* <button style={{background:buttonColor}}>{buttonLabel}</button> */}
              </div>
              <div className='careers-container-sub-2'>
                <ul>
                   <li style={{ color: fontColor }} >Location: {currentJobSubDetail?.location }</li>
                   <li style={{ color: fontColor }} >Experience: {currentJobSubDetail?.experience }</li>
                </ul>
              </div>
    
              <div className='careers-container-sub-3-line'></div>
    
              <div className='careers-container-sub-3-question'>
                {questions.question1.map((value,index) => (
                    <TitleFragment key={index} {...value}/>
                ))}
                <p>{currentJobDesc?.content}</p>
              </div>
    
              <div className='careers-container-sub-3-job'>
                {questions.question2.map((value,index) => (
                      <TitleFragment key={index} {...value}/>
                ))}
                
                   <ul>
                  {questions.answer2.map((list,unique) => (
                    <li key={unique}>{list.points}</li>
                  ))}
                </ul>
              </div>
    
              <div  className='careers-container-sub-3-job'>
                  {
                    currentJob && currentJob.jobPurpose.title.map((value,index) => (
                      <TitleFragment key={index} {...value}/>
                ))
                }
                  <p style={{fontSize:'0.85rem',fontWeight:'500',color:'black'}}>{ currentJob ? currentJob.jobPurpose.heading:''}</p>
                <ul>
                    {
                      currentJob && currentJob.jobPurpose?.list1?.map((list,unique) => (
                    <li key={unique}>{list.points}</li>
                  ))
                  }
                </ul>
              </div>
    
              <div  className='careers-container-sub-3-job'>
                  {
                    currentJob && currentJob.requirements?.title?.map((value,index) => (
                      <TitleFragment key={index} {...value}/>
                ))
                }
                <ul>
                    {
                      currentJob && currentJob.requirements?.list2?.map((list,unique) => (
                    <li key={unique}>{list.points}</li>
                  ))
                  }
                </ul>
                </div>
                <div  className='careers-container-sub-3-job'>
                  {
                    currentJob && currentJob.requirements2?.title?.map((value,index) => (
                      <TitleFragment key={index} {...value}/>
                ))
                }
                <ul>
                    {
                      currentJob && currentJob.requirements2?.list2?.map((list,unique) => (
                    <li key={unique}>{list.points}</li>
                  ))
                  }
                </ul>
                </div>
                
                <div  className='careers-container-sub-3-job'>
                {joinUs.title.map((value,index) => (
                      <TitleFragment key={index} {...value}/>
                ))}
                  <p style={{fontSize:'0.85rem',fontWeight:'500',color:'black'}}>{ currentJoinUsData?.heading}</p>
                  <p style={{fontSize:'0.85rem',fontWeight:'500',color:'black'}}>{ joinUs.heading2}</p>
              </div>
              </div>


              {/* right section (form) */}
              <div style={{ width: width > 768 ? '40%' : '100%' }} className="careers-right-wrapper">
                

                {
                  isSubmitted?<p style={{color:'#E65E27',fontSize:'1.3rem',fontWeight:'500'}}>Thank You ! Your application is submitted successfully.</p>: <div style={{padding:width>768?'2rem':'1rem',borderRadius:'10px',boxShadow:'rgba(0, 0, 0, 0.24) 0px 3px 8px'}} className="careers-right">
          <p style={{textAlign:'center',fontWeight:'500',fontSize:'1.2rem'}} className="apply-title">Apply for this position </p>
          <form onSubmit={handleSubmit} style={{display:'flex',flexDirection:'column',marginTop:'2rem'}} className="apply-form">
                    <label style={{ fontSize: '0.9rem', fontWeight: '500',marginBottom:'0.3rem' }}>Name
                      <sup style={{color:'red'}}>*</sup>
            </label>
                    <input style={{ marginBottom: '1.2rem' }} type="text" name="name" value={formData?.name} required onChange={(e) => setFormData((prevState) => ({
                      ...prevState,
                      name:e.target.value
                    }))} />
                    {formErrors.name && <span className='error-text'>{formErrors.name}</span>}

            <label style={{ fontSize: '0.9rem', fontWeight: '500',marginBottom:'0.3rem' }}>Email<sup style={{color:'red'}}>*</sup></label>
                    <input style={{ marginBottom: '1.2rem' }} type="email" name="email" value={formData?.email} required  onChange={(e) => setFormData((prevState) => ({
                      ...prevState,
                      email:e.target.value
                    }))} />
                    {formErrors.email && <span className='error-text'>{formErrors.email}</span>}

            <label style={{ fontSize: '0.9rem', fontWeight: '500',marginBottom:'0.3rem' }}>Phone<sup style={{color:'red'}}>*</sup></label>
                    <input style={{ marginBottom: '1.2rem' }} type="tel" name="phone" value={formData?.phone} required onChange={(e) => setFormData((prevState) => ({
                      ...prevState,
                      phone:e.target.value
                    }))}  />
                      {formErrors.phone && <span className='error-text'>{formErrors.phone}</span>}
                  
                    

                  <label style={{ fontSize: '0.9rem', fontWeight: '500',marginBottom:'0.3rem' }}>Upload CV/Resume</label>
                    <input style={{ marginBottom: '1.2rem', border: '1px solid gray', borderRadius: '6px' }} type="file" name="phone" required onChange={(e) => {
                      console.log('file:::::',e.target.files[0])
                      console.log('file target:::::', e.target)
                      setFormData((prevState) => ({
                      ...prevState,
                      attachment:e.target.files[0]
                    }))
                    }} />
                      {formErrors.attachment && <span className='error-text'>{formErrors.attachment}</span>}
                      

                      <label style={{ fontSize: '0.9rem', fontWeight: '500',marginBottom:'0.3rem' }}>Message</label>
                    <textarea rows={4} style={{ marginBottom: '1.2rem' }} type="text" name="message" value={formData?.message} required onChange={(e) => setFormData((prevState) => ({
                      ...prevState,
                      message:e.target.value
                    }))}  />
                    
                    <div style={{display:'flex',justifyContent:'center',alignItems:'start',gap:'2%',marginBottom:'2rem'}}>
                      <input type="checkbox" style={{ marginTop: '4px' }} value={ formData?.isChecked} onChange={(e) => setFormData((prevState) => ({
                      ...prevState,
                      isChecked:e.target.checked
                    }))}  />
                      <p style={{fontSize:'0.8rem',fontWeight:'500'}}>
                        By using this form you agree with the storage and handling of your data by PharmSight.<sup style={{color:'red'}}>*</sup>
                      </p>
                      {formErrors.isChecked && <span className='error-text'>{formErrors.isChecked}</span>}
                    </div>

                      <button style={{ backgroundColor: '#E65E27', color: 'white', outline: 'none', border: 'none',  width: '8rem',height:'2.5rem',cursor:'pointer' }} type='submit'>
                        {
                          emailLoading ? <span className="loader-email" ></span>:'Apply now'
                        }
            </button>
          </form>
        </div>
               }
              </div>
               
             </div>
    
            </div>
        </div>
      )
}
    




export const CareerSubPageTemplate = ({
    backButton,
    fontColor,
    title,
    jobTitle,
    buttonLabel,
    buttonColor,
    logo,
    subDetails,
    questions,
    jobData,
    joinUs
  }) => {
    
    return (
      <div>
        <PageContent 
            backButton={backButton}
            fontColor={fontColor}
            title={title}
            buttonLabel={buttonLabel}
            buttonColor={buttonColor}
            logo={logo}
            subDetails={subDetails}
            questions={questions}
            jobData={jobData}
          joinUs={joinUs}
          jobTitle={jobTitle}
        />
      </div>
    );
  };


CareerSubPageTemplate.propTypes = {
    backButton: PropTypes.string.isRequired,
    fontColor: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    buttonColor: PropTypes.string.isRequired,
  logo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    jobTitle: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
      }).isRequired,
    ).isRequired,
    subDetails: PropTypes.arrayOf(
      PropTypes.shape({
        location: PropTypes.string.isRequired,
        experience: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
      }).isRequired,
    ).isRequired,
    questions: PropTypes.shape({
      question1: PropTypes.arrayOf(
        PropTypes.shape({
          fragment: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      answer1: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          content: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      question2: PropTypes.arrayOf(
        PropTypes.shape({
          fragment: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      answer2: PropTypes.string.isRequired,
    }).isRequired,
  jobData: PropTypes.shape({
    id: PropTypes.number.isRequired,
      jobPurpose: PropTypes.shape({
      title: PropTypes.arrayOf(
        PropTypes.shape({
          fragment: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      heading: PropTypes.string.isRequired,
      list1: PropTypes.arrayOf(
        PropTypes.shape({
          points: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
    requirements: PropTypes.shape({
      title: PropTypes.arrayOf(
        PropTypes.shape({
          fragment: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      list2: PropTypes.arrayOf(
        PropTypes.shape({
          points: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
    requirements2: PropTypes.shape({
      title: PropTypes.arrayOf(
        PropTypes.shape({
          fragment: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      list2: PropTypes.arrayOf(
        PropTypes.shape({
          points: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
    }).isRequired,
    joinUs: PropTypes.shape({
      title: PropTypes.arrayOf(
        PropTypes.shape({
          fragment: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          heading: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      heading2: PropTypes.string.isRequired,
    }).isRequired,
  };


const CareerSubpage = ({ data }) => {
    const {frontmatter} = data.markdownRemark;
       
  return (
    <Layout>
      <CareerSubPageTemplate
        backButton = {frontmatter.backButton}
        fontColor = {frontmatter.fontColor}
        title = {frontmatter.title}
        jobTitle = {frontmatter.jobTitle}
        buttonLabel = {frontmatter.buttonLabel}
        buttonColor = {frontmatter.buttonColor}
        logo= {frontmatter.logo}
        subDetails = {frontmatter.subDetails}
        questions= {frontmatter.questions}
        jobData={frontmatter.jobData}
        joinUs= {frontmatter.joinUs}
      />
    </Layout>
  );
};

export default CareerSubpage;

export const pageQuery = graphql`
  query CareerSubPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "career-subpage" } }) {
      frontmatter {
        backButton
        fontColor
        buttonLabel
        buttonColor
        logo {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
        subDetails {
          id
          location
          experience
        }
        title 
        jobTitle {
          id
          heading
        }
        questions {
          question1 {
            fragment
            color
          }
          answer1 {
           id
           content
          }
          question2 {
            fragment
            color
          }
          answer2 {
           points
          }
        }
        jobData {
        id
        jobPurpose {
		  title {
            fragment
            color
          }
            heading
          list1 {
            points
          }
        }
        requirements {
		  title {
            fragment
            color
          }
          list2 {
            points
          }
        }
        requirements2 {
		  title {
            fragment
            color
          }
          list2 {
            points
          }
        }
        }
          joinUs {
		  title {
            fragment
            color
          }
      data {
       id
       heading
      }
          heading2
        }
      }
    }
  }
`;

